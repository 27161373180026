<template>
  <div class="gallery-form">
    <div ref="form" class="gallery-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>
      <v-row>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Title</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Title"
            v-model="form.title"
            :error-messages="form.$getError('title')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_active"
                hide-details="auto"
                label="Set as active"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_published"
                hide-details="auto"
                label="Publish on TV"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Images</label>
          <GalleryFileUpload
            ref="imagesUploader"
            class="my-2"
            :loading="form.$busy"
            :error="form.$getError('images')"
            :for-update="isUpdate"
            @updated="form.$clearError('images')"
            :showUploadImageBtn="hasUpdatePermission"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              @click="submit"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Gallery Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectBuilding from '@/components/fields/SelectBuilding'
import GalleryFileUpload from '@/views/Home/Gallery/components/GalleryFileUpload'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectBuilding,
    GalleryFileUpload,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    gallery: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        title: '',
        is_active: false,
        is_published: false,
        building_id: '',
        images: [],
      }),
    }
  },

  computed: {
    ...mapState({
      createdGallery: (state) => state.gallery.galleryDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Album Information' : 'Create Album'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateGallery : this.createGallery
    },

    isUpdate() {
      return !!this.gallery
    },

    hasUpdatePermission() {
      return validatePermissions(
        [
          PERMISSION.GALLERIES_UPDATE,
          PERMISSION.GALLERIES_DELETE,
          PERMISSION.GALLERIES_CREATE,
        ],
        this.permissions
      )
    },
  },

  watch: {
    gallery(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createGallery: 'gallery/createGallery',
      updateGallery: 'gallery/updateGallery',
    }),

    initForm() {
      if (this.gallery) {
        this.form.title = this.gallery.title
        this.form.is_active = this.gallery.is_active
        this.form.is_published = this.gallery.is_published
        this.form.building_id = this.gallery.building.id

        this.form.images = this.gallery.images
      }
    },
    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      /**
       * v-comboxbox value not getting updated when its still on focus
       * and submit was run
       * https://github.com/vuetifyjs/vuetify/issues/3424
       */
      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Gallery details successfully updated!')
          } else {
            this.showSnackbar('Gallery successfully created!')
            this.$router.push({
              name: 'gallery',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()

      delete form.images

      if (this.isUpdate) {
        form.id = this.gallery.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          switch (field) {
            /* ...append normally everything else */
            default:
              formData.append(field, form[field])
              break
          }
        }

        this.$refs.imagesUploader.getImages(formData)

        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.gallery-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
