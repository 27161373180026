<template>
  <div class="create-gallery">
    <GalleryAppbar />
    <div class="ma-4">
      <GalleryForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Gallery
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import GalleryAppbar from '@/views/Home/Gallery/components/GalleryAppbar'
import GalleryForm from '@/views/Home/Gallery/components/GalleryForm'

export default {
  components: {
    GalleryAppbar,
    GalleryForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'gallery/clearGalleryDetails',
    }),
  },
}
</script>
<style lang="scss">
.create-gallery {
  //
}
</style>
